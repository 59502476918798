import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MaterialDialogModule } from '../material-dialog/material-dialog.module';
import { StreakInfoComponent } from './components/streak-info/streak-info.component';
import { TranslocoModule } from '@jsverse/transloco';
import { StreakDisplayComponent } from './components/streak-display/streak-display.component';
import { StreakDialogComponent } from './components/streak-dialog/streak-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { StreakSectionComponent } from './components/streak-section/streak-section.component';
import { ProgressBarChartComponent } from '../charts/progress-bar-chart/component/progress-bar-chart/progress-bar-chart.component';
import { BaseDialogComponent } from '../dialogs/base-dialog/base-dialog.component';
import { FutCardComponent } from '../components/fut-card/fut-card.component';
import { GeneralSectionComponent } from '../components/general-section/general-section.component';
import { GhostFragmentComponent } from '@futura/futura-ui/ghost';

@NgModule({
  declarations: [StreakInfoComponent, StreakDisplayComponent, StreakDialogComponent, StreakSectionComponent],
  exports: [StreakInfoComponent, StreakDisplayComponent, StreakSectionComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialDialogModule,
    TranslocoModule,
    MatIconModule,
    ProgressBarChartComponent,
    BaseDialogComponent,
    FutCardComponent,
    GeneralSectionComponent,
    GhostFragmentComponent,
  ],
  providers: [],
})
export class StreakModule {}
